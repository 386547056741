<template>
  <div>
    <topbar />

    <main-table />
  </div>
</template>

<script>
import MainTable from '@/components/refbringers/RefbringersMainTable.vue';
import Topbar from '@/components/refbringers/RefbringersTopbar.vue';

export default {
  name: 'Refbringers',

  components: {
    MainTable,
    Topbar,
  },

  created() {
    this.$store.dispatch('users/loadRefbringers');
  },

  beforeDestroy() {
    this.$store.dispatch('users/clearSelected');
  },
};
</script>
