var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-data-table',{attrs:{"items":_vm.users.all,"item-key":"id","headers":_vm.cols,"fixed-header":"","height":_vm.innerHeight - 20 - 48 - 59 - 16,"items-per-page":_vm.footerOptions.itemsPerPage,"hide-default-footer":"","show-select":"","loading":_vm.loading.mainTable,"server-items-length":1,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var user = ref.item;
return [_vm._v(" "+_vm._s(("https://anty.dolphin.ru.com/a/" + (user.id)))+" ")]}},{key:"item.addUserButton",fn:function(ref){
var user = ref.item;
return [_c('v-btn',{attrs:{"small":"","title":"Посмотреть историю транзакций"},on:{"click":function($event){return _vm.openDialog(user)}}},[_vm._v(" Привязать юзера ")])]}},{key:"item.username",fn:function(ref){
var user = ref.item;
return [_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.openRatesHistoryDialog(user)}}},[_vm._v(" "+_vm._s(user.username)+" ")])]}},{key:"item.earned",fn:function(ref){
var user = ref.item;
return [(user.earned>0)?_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.openPaymentsHistoryDialog(user)}}},[_vm._v(" "+_vm._s(user.earned)+" ")]):_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(user.earned)+" ")])]}},{key:"footer",fn:function(){return [_c('v-data-footer',{staticClass:"footer-pagination",attrs:{"options":_vm.footerOptions,"pagination":_vm.footerPagination,"items-per-page-options":[10, 30, 50]},on:{"update:options":_vm.updateFooterOptions}})]},proxy:true}],null,true),model:{value:(_vm.users.selected),callback:function ($$v) {_vm.$set(_vm.users, "selected", $$v)},expression:"users.selected"}})],1),(_vm.dialogs.showRefPaymentsHistory)?_c('refbringers-dialog-ref-transactions-history'):_vm._e(),(_vm.dialogs.showRefRatesHistory)?_c('refbringers-dialog-ref-rates-history'):_vm._e(),(_vm.dialogs.addRefUser)?_c('refbringers-dialog-add-ref-user',{on:{"add":_vm.addRefUser}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }