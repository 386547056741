<template>
  <v-dialog
    :value="dialogs.addRefUser"
    width="600"
    :overlay-opacity="0.9"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <!-- ЗАГОЛОВОК -->
      <v-toolbar
        :height="48"
        dark
        color="primary"
        class="pr-0"
      >
        <v-toolbar-title>Привязать юзера / юзеров из списка</v-toolbar-title>

        <v-spacer />

        <v-toolbar-items>
          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pt-5">
        <v-text-field
          v-model="userEmail"
          hide-details
          label="Email"
        />
      </v-card-text>

      <v-file-input
        v-model="file"
        label="Добавьте список в *.csv"
      ></v-file-input>

      <v-divider />

      <!-- КНОПКИ ДИАЛОГА -->
      <v-card-actions>
        <v-spacer />

        <v-btn
          small
          text
          color="grey"
          @click="closeDialog"
        >
          Закрыть
        </v-btn>

        <v-btn
          small
          text
          color="primary"
          :loading="loading"
          @click="closeDialog(); $emit('add', {userEmail, file})"
        >
          Привязать
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'RefbringersDialogAddRefUser',

  data() {
    return {
      loading: false,
      userEmail: '',
      file: '',
    };
  },

  computed: {
    ...mapGetters({
      users: 'users/users',
      dialogs: 'users/dialogs',
    }),
  },

  methods: {
    closeDialog() {
      this.$store.dispatch('users/closeDialog', 'addRefUser');
    },
  },

};
</script>
