<template>
  <div>
    <v-card>
      <!-- ТАБЛИЦА -->
      <v-data-table
        v-model="users.selected"
        :items="users.all"
        item-key="id"
        :headers="cols"
        fixed-header
        :height="innerHeight - 20 - 48 - 59 - 16"
        :items-per-page="footerOptions.itemsPerPage"
        hide-default-footer
        show-select
        :loading="loading.mainTable"
        :server-items-length="1"
        :options.sync="options"
      >
        <!-- Рефссылка -->
        <template #[`item.id`]="{item: user}">
          {{ `https://anty.dolphin.ru.com/a/${user.id}` }}
        </template>

        <!-- Кнопка для привязки юзера -->
        <template #[`item.addUserButton`]="{item: user}">
          <v-btn
            small
            title="Посмотреть историю транзакций"
            @click="openDialog(user)"
          >
            Привязать юзера
          </v-btn>
        </template>

        <!-- Кнопка для просмотра истории повышений комиссии -->
        <template #[`item.username`]="{item: user}">
          <v-btn
            small
            @click="openRatesHistoryDialog(user)"
          >
            {{ user.username }}
          </v-btn>
        </template>

        <!-- Кнопка для просмотра истории начислений -->
        <template #[`item.earned`]="{item: user}">
          <v-btn
            v-if="user.earned>0"
            small
            @click="openPaymentsHistoryDialog(user)"
          >
            {{ user.earned }}
          </v-btn>
          <div
            v-else
            class="text-center"
          >
            {{ user.earned }}
          </div>
        </template>

        <!-- ФУТЕР -->
        <template #footer>
          <v-data-footer
            class="footer-pagination"
            :options="footerOptions"
            :pagination="footerPagination"
            :items-per-page-options="[10, 30, 50]"
            @update:options="updateFooterOptions"
          />
        </template>
      </v-data-table>
    </v-card>

    <refbringers-dialog-ref-transactions-history v-if="dialogs.showRefPaymentsHistory" />

    <refbringers-dialog-ref-rates-history v-if="dialogs.showRefRatesHistory" />

    <refbringers-dialog-add-ref-user
      v-if="dialogs.addRefUser"
      @add="addRefUser"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import RefbringersDialogAddRefUser from '@/components/refbringers/RefbringersDialogAddRefUser.vue';
import RefbringersDialogRefTransactionsHistory from '@/components/refbringers/RefbringersDialogRefTransactionsHistory.vue';
import RefbringersDialogRefRatesHistory from '@/components/refbringers/RefbringersDialogRefRatesHistory.vue';

export default {
  name: 'RefbringersMainTable',

  components: {
    RefbringersDialogRefTransactionsHistory,
    RefbringersDialogAddRefUser,
    RefbringersDialogRefRatesHistory,
  },

  data() {
    return {
      moment,
      refUser: null,
      options: {},
    };
  },

  computed: {
    ...mapGetters({
      loading: 'users/loading',
      innerHeight: 'main/innerHeight',
      pagination: 'users/pagination',
      dialogs: 'users/dialogs',
      topbarSearch: 'users/topbarSearch',
    }),
    ...mapFields('users', ['users']),

    cols() {
      const cols = [];

      cols.push({ text: 'Почта', value: 'username' });
      cols.push({ text: 'Телеграм', value: 'telegram' });
      cols.push({ text: 'Рефссылка', value: 'id' });
      cols.push({ text: 'Промокод', value: 'promo' });
      cols.push({ text: 'Кол-во рефов', value: 'refsCount' });
      cols.push({ text: 'Заработал, USD', value: 'earned' });
      cols.push({ text: '', value: 'addUserButton', sortable: false });
      cols.push({ text: '', value: 'history', sortable: false });

      return cols;
    },

    footerOptions() {
      const options = {};

      options.page = this.pagination.currentPage;
      options.itemsPerPage = this.pagination.perPage;

      return options;
    },

    footerPagination() {
      const pagination = {};

      pagination.page = this.pagination.currentPage;
      pagination.itemsPerPage = this.pagination.perPage;
      pagination.pageStart = this.pagination.from - 1;
      pagination.pageStop = this.pagination.to;
      pagination.pageCount = this.pagination.lastPage;
      pagination.itemsLength = this.pagination.total;

      return pagination;
    },
  },

  watch: {
    options() {
      this.updateSort();
    },
  },

  methods: {
    updateFooterOptions(options) {
      const payload = {
        page: options.page,
        itemsPerPage: options.itemsPerPage,
        query: this.topbarSearch.refbringersSearchInput,
      };

      if (typeof this.options.sortBy.length !== 'undefined') {
        [payload.sortBy] = this.options.sortBy;
        [payload.sortDesc] = this.options.sortDesc;
      }

      this.$store.dispatch('users/loadRefbringers', payload);
    },

    openDialog(user) {
      this.refUser = user;

      this.$store.dispatch('users/openDialog', 'addRefUser');
    },
    openPaymentsHistoryDialog(user) {
      this.users.forDialog.user = user;
      console.log(user);
      this.$store.dispatch('users/loadRefUserRefTransactionsHistory', { userId: user.id });
      this.$store.dispatch('users/openDialog', 'showRefPaymentsHistory');
    },
    openRatesHistoryDialog(user) {
      this.users.forDialog.user = user;
      console.log(user);
      this.$store.dispatch('users/loadRefUserRefRatesHistory', { userId: user.id });
      this.$store.dispatch('users/openDialog', 'showRefRatesHistory');
    },

    addRefUser({ userEmail, file }) {
      if (file !== '' && file !== undefined) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('refUserId', this.refUser.id);
        this.$store.dispatch('users/addRefUserList', { formData });
      } else {
        this.$store.dispatch('users/addRefUser', { refUserId: this.refUser.id, userEmail });
      }
    },

    updateSort() {
      const payload = {
        page: this.footerPagination.page,
        itemsPerPage: this.footerPagination.itemsPerPage,
        query: this.topbarSearch.refbringersSearchInput,
      };

      if (typeof this.options.sortBy.length !== 'undefined') {
        [payload.sortBy] = this.options.sortBy;
        [payload.sortDesc] = this.options.sortDesc;
      }

      this.$store.dispatch('users/loadRefbringers', payload);
    },
  },
};
</script>

<style>
.footer-pagination .v-data-footer__pagination {
  display: none;
}
</style>
