<template>
  <div
    class="d-flex mb-5 align-center"
    style="position: relative; z-index: 10"
  >
    <v-flex
      v-click-outside="() => {filterDatesOpened = false}"
      xs3
      style="position: relative"
    >
      <v-text-field
        v-model="$route.query.filterDates"
        hide-details
        label="Дата"
        clearable
        readonly
        @focus="filterDatesOpened = true"
        @click:clear="onClearFilterDate"
      />

      <v-date-picker
        v-if="filterDatesOpened"
        v-model="filterDates"
        range
        no-title
        full-width
        class="datePicker"
        color="light-blue"
      >
        <v-btn
          small
          @click="setFilterDatesToLastMonth"
        >
          Последний месяц
        </v-btn>

        <v-btn
          small
          @click="setFilterDatesToLastYear"
        >
          последний год
        </v-btn>
      </v-date-picker>
    </v-flex>

    <v-spacer />

    <div>
      <!-- ПОИСК -->
      <v-text-field
        :value="topbarSearch.refbringersSearchInput"
        label="Поиск"
        dense
        solo
        single-line
        hide-details
        clearable
        clear-icon="mdi-close"
        :spellcheck="false"
        @input="search"
      >
        <template #prepend-inner>
          <v-icon size="18">
            mdi-magnify
          </v-icon>
        </template>
      </v-text-field>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'RefbringersTopbar',

  data() {
    return {
      filterDates: [],
      filterDatesOpened: false,
    };
  },

  watch: {
    filterDates(value) {
      if (value.length !== 1) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            filterDates: value.length ? value.map((date) => moment(date).format('DD.MM.YYYY')).join('-') : '',
          },
        });

        this.$store.dispatch('users/loadRefbringers', {
          page: this.pagination.currentPage,
          itemsPerPage: this.pagination.perPage,
          query: this.topbarSearch.refbringersSearchInput,
        });
      }
    },
  },

  computed: {
    ...mapGetters({
      profile: 'main/profile',
      pagination: 'users/pagination',
      topbarSearch: 'users/topbarSearch',
    }),
  },

  mounted() {
    if (this.$router.query?.filterDates) {
      this.filterDates = this.$router.query.filterDates.split('-');
    }
  },

  methods: {
    setFilterDatesToLastMonth() {
      this.filterDates = [
        moment().startOf('month').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD'),
      ];
    },

    setFilterDatesToLastYear() {
      this.filterDates = [
        moment().startOf('year').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD'),
      ];
    },

    onClearFilterDate() {
      this.filterDates = [];
    },

    search(name) {
      this.topbarSearch.refbringersSearchInput = name;
      setTimeout(async () => {
        if (name === this.topbarSearch.refbringersSearchInput) {
          this.$store.dispatch('users/loadRefbringers', {
            itemsPerPage: this.pagination.perPage,
            query: this.topbarSearch.refbringersSearchInput,
          });
        }
      }, 500);
    },
  },
};
</script>

<style scoped>
  .datePicker{
    position: absolute;
    margin-top: 1px;
  }
</style>
