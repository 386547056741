<template>
  <v-dialog
    :value="dialogs.showRefPaymentsHistory"
    width="1080"
    :overlay-opacity="0.9"
    scrollable
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <!-- ЗАГОЛОВОК -->
      <v-toolbar
        :height="48"
        dark
        color="primary"
        class="pr-0"
      >
        <v-toolbar-title>
          Рефские начисления для {{ users.forDialog.user.username }}
        </v-toolbar-title>

        <v-spacer />

        <v-toolbar-items>
          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pt-5">
        <v-data-table
          id="affiliateMainTable"
          :items="userRefTransactions"
          :headers="cols"
          :items-per-page="-1"
          hide-default-footer
        >
          <!-- ДАТА -->
          <template #[`item.date`]="{item}">
            <span>
              {{ moment.parseZone(item.created_at).local().locale('ru').format("DD MMM HH:mm") }}
            </span>
          </template>
          <!-- ДАТА -->
          <template #[`item.sum`]="{item}">
            <span>
              {{ item.sum }} {{ item.currency }} => {{ (item.sum/item.exchange).toFixed(2) }} USD
            </span>
          </template>
        </v-data-table>
      </v-card-text>

      <v-divider />

      <!-- КНОПКИ ДИАЛОГА -->
      <v-card-actions>
        <v-spacer />

        <v-btn
          small
          text
          color="grey"
          @click="closeDialog"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'RefbringersDialogRefTransactionsHistory',

  data() {
    return {
      moment,
      loadingCancelButton: false,
    };
  },

  computed: {
    ...mapGetters({
      dialogs: 'users/dialogs',
      users: 'users/users',
      userRefTransactions: 'users/userRefTransactions',
    }),

    cols() {
      const cols = [];

      cols.push({ text: 'Дата', value: 'created_at', width: 180 });
      cols.push({
        text: 'Сумма => Сумма, USD', value: 'sum', width: 200, sortable: false,
      });
      cols.push({
        text: 'Курс', value: 'exchange', width: 100, sortable: false,
      });
      cols.push({
        text: '%', value: 'refRate', width: 20, sortable: false,
      });
      cols.push({
        text: 'За кого', value: 'name', width: 150, sortable: false,
      });
      cols.push({
        text: 'Дата создания', value: 'teamCreated', width: 180, sortable: false,
      });
      cols.push({
        text: 'Выплата', value: 'paid', width: 20, sortable: false,
      });

      return cols;
    },
  },

  methods: {
    closeDialog() {
      debugger;
      this.$store.dispatch('users/closeDialog', 'showRefPaymentsHistory');
    },
  },

};
</script>
